import { Flex, Td, Text, Tr } from "@chakra-ui/react";

function WinnersTemplateTable({ data, openUserCard, openQrCard, prize }) {
    return (
        <>
            {data.map((row, i) => (
                <Tr key={i}>
                    <Td>
                        <Flex align='center'>
                            <Text
                                w='min-content'
                                role='button'
                                fontSize='md'
                                fontWeight='bold'
                                onClick={() => openUserCard(row.account_id)}
                            >
                                {row.account_id || "-"}
                            </Text>
                        </Flex>
                    </Td>

                    <Td>
                        <Flex align='center'>
                            <Text fontSize='md' fontWeight='bold'>
                                {row.email || "-"}
                            </Text>
                        </Flex>
                    </Td>

                    <Td>
                        <Flex align='center'>
                            <Text color='gray.400' fontSize='md'>
                                {row.fio || "-"}
                            </Text>
                        </Flex>
                    </Td>

                    <Td>
                        <Flex align='center'>
                            <Text fontSize='md' fontWeight='bold'>
                                {row.phone || "-"}
                            </Text>
                        </Flex>
                    </Td>

                    <Td>
                        <Flex align='center' w='min-content'>
                            <Text
                                color='gray.400'
                                fontSize='md'
                                role='button'
                                onClick={() => openQrCard(row.id)}
                            >
                                {row.id || "-"}
                            </Text>
                        </Flex>
                    </Td>

                    <Td>
                        <Flex align='center'>
                            <Text color='gray.400' fontSize='md'>
                                {prize === "guaranteedPrize" &&
                                    `Гарантированный приз ${row.code || ""}`}
                                {prize === "mattiP5" && "«Mätti» П5"}
                                {prize === "blender" && "Блендер RED SOLUTION RSB-3427 черный"}
                                {prize === "kettle" &&
                                    "Чайник RED SOLUTION Sky Kettle RK-M216S белый"}
                                {prize === "multicooker" && "Мультиварка RED SOLUTION RMC-M04"}
                                {prize === "miniBakery" && "Минипекарня RED SOLUTION RMB-M603"}
                            </Text>
                        </Flex>
                    </Td>
                </Tr>
            ))}
        </>
    );
}

export default WinnersTemplateTable;
