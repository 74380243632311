import { useState, useEffect } from "react";
import axios from "axios";

export default function useRequestArray(path, method) {
    const [getRequestData, setRequestData] = useState({
        responseData: [],
        isLoading: false,
        isError: false,
        error: "",
    });

    useEffect(() => {
        async function getData() {
            try {
                setRequestData({ ...getRequestData, isLoading: true, isError: false });

                const { data } = await axios({
                    url: `${process.env.REACT_APP_API_URL}/${path}`,
                    method,
                });

                if (!ignore) {
                    setRequestData({
                        ...getRequestData,
                        isLoading: false,
                        isError: false,
                        responseData: data.data,
                    });
                }
            } catch (error) {
                setRequestData({
                    ...getRequestData,
                    isLoading: false,
                    isError: true,
                    error: error.message,
                });
            }
        }

        let ignore = false;
        getData();
        return () => {
            ignore = true;
        };
    }, [path]);

    return getRequestData;
}
